<template>
    <div>
        <ul class="tab-navs spmg-bottom-db">
            <li class="tn-item current">
                我的收藏
            </li>
        </ul>
        <ul class="favorites-list" v-show="isNum==1">
            <li class="post" v-for="(item,index) in clearList" :key="index"> 
                <div class="ctrl-area" @click="cancel(item)">
                    <i class="iconfont iconaixin"></i>
                    <p>取消关注</p>
                </div>
                <div class="inners clearfix">
                    <router-link :to="{name:'Detailspage',query:{type:item.type,code:item.code}}" target="_blank" class="media-pics fl">
                        <img :src="item.pid==0?item.cover:item.image" alt="">
                    </router-link>
                    <div class="media-body">
                        <div class="hd">
                            <span class="post-date fr">{{item.time}}</span>
                            <span class="post-cates"><em class="cate-tag-icon" :class="item.type==1?'catePPt':'cateWord'">{{item.type==1?'BP演示版':'BP文档版'}}</em></span>
                        </div>
                        <div class="title">
                            <a href="">{{item.name}}</a>
                        </div>
                        <p class="post-meta">
                            <em>
                                <i class="iconfont iconai-eye"></i>{{item.pv}}
                            </em>
                            <em>
                                <i class="iconfont iconaixin"></i>{{item.laud}}
                            </em>
                        </p>
                    </div>
                </div>
            </li>
        </ul>
        <div class="d_list_empty" v-show="isNum==2">
                <i class="m-list-empty-img"></i>
                <span class="m-list-empty-txt">还没有收藏模板</span>
                <router-link :to="{name:'indexPage'}">
                    <span class="m-list-empty-btn">
                        去首页看看
                    </span>
                </router-link>
            </div>
    </div>
</template> 
<script>
export default {
    data () {
        return {
            clearList:[],
            clearLists:[],
            isNum:0
        }
    },
    methods: {
        download(){
            this.axios.mylike({
                params:{
                    token:this.$store.state.token,
                }
            }).then(res=>{
                if(res.data.code==0){
                    var arr1 = res.data.data.ppt.rows
                    var arr2 = res.data.data.word.rows
                    var arr3=[...arr1, ...arr2]
                    this.clearList = arr3
                    if(this.clearList.length!=0){
                        this.isNum = 1
                    }else{
                        this.isNum = 2
                    }
                }else{
                    this.clearList = []
                }
            }).catch(err=>{
                console.log(err)
            })
        },
        //取消收藏
        cancel(item){
            this.axios.collectionlike({
                token:this.$store.state.token,
                code:item.code,
                state:'f'
            }).then(res=>{
                if(res.data.code==0){
                    this.download()
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    },
    watch: {
    },
    mounted() {
        this.download()
    }
}
</script>
<style scoped>
@import "./../../styles/Page/Downloadlatest.css";
</style>